import React from "react";
import "./styles.css";

const Terms = () => {
  return (
    <div className="terms">
      <div className="nav">
        <a href="/" className="xvoucher-logo-link">
          <img
            className="xvoucher-logo"
            alt="Xvoucher logo"
            src="/img/xvoucher-logo.svg"
          />
        </a>
        <div className="how-guide">
          <div className="overlap-group">
            <a href="/how-to-guide" className="text-wrapper">
              how to guide
            </a>
            <img className="vector" alt="Vector" src="/img/vector.svg" />
          </div>
        </div>
      </div>
      <div className="content">
        <h1>Terms and Conditions</h1>
        <p>Last modified: 27 June 2024</p>

        <h2>Introduction</h2>
        <p>
          Welcome to XVoucher, a platform provided by XVoucher for the
          redemption of vouchers for USDT (Tether) stablecoin cryptocurrency. By
          accessing or using our services, you signify that you have read,
          understand, and agree to be bound by this Agreement in its entirety.
          If you do not agree, you are not authorized to access or use the
          services and should not use them. These Terms and Conditions, together
          with our privacy policy, govern your relationship with us.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using the services provided by XVoucher, you accept
          and agree to be bound by the terms and conditions set forth in this
          Agreement. This Agreement constitutes a legally binding contract
          between you and XVoucher. If you do not agree with any part of these
          terms, you must immediately stop accessing and using our services.
        </p>

        <h2>2. Description of Services</h2>
        <p>
          XVoucher offers a platform for the redemption of vouchers for USDT
          (Tether) stablecoin cryptocurrency. This is a closed-loop system,
          meaning that the USDT acquired can only be used at participating South
          African retailers, both online and in-store. The USDT cannot be
          withdrawn or transferred to other wallets or platforms. XVoucher
          utilizes decentralized, non-custodial wallet technology, allowing
          users to connect any existing web3 wallet or create one via email or
          mobile. Users are responsible for the security of their wallets and
          private keys.
        </p>

        <h2>3. How to Interpret These Terms and Conditions</h2>
        <p>
          In these terms and conditions, the following expressions shall bear
          the meanings assigned to them unless the context clearly shows that
          the parties intended a different meaning:
        </p>
        <ul>
          <li>
            <strong>“Access Codes”</strong> means any of your secret numbers
            used to access our services, including your password, username,
            biometrics, and secret word.
          </li>
          <li>
            <strong>“App”</strong> means the XVoucher application for your
            device.
          </li>
          <li>
            <strong>“Device”</strong> means the device you use to access the App
            or Website.
          </li>
          <li>
            <strong>“XVoucher”, “we”, “us”, “our”</strong> means XVoucher the
            provider of the XVoucher platform.
          </li>
          <li>
            <strong>“Personal Information”</strong> means personal information
            as defined in applicable data protection laws.
          </li>
          <li>
            <strong>“Products”</strong> means any goods or services available
            through participating retailers.
          </li>
          <li>
            <strong>“Provider”</strong> means the owner, supplier, or
            distributor of a Product.
          </li>
          <li>
            <strong>“Transactions”</strong> mean the different transactions or
            activities relating to a Product that can be performed by you via
            the App or Website.
          </li>
          <li>
            <strong>“Website”</strong> means www.xvoucher.io and any other
            related websites operated by XVoucher.
          </li>
          <li>
            <strong>“You” or “your”</strong> means the person who registered for
            the App, uses the Website or App, or purchases/redeems a voucher.
          </li>
        </ul>

        <h2>4. Voucher Redemption</h2>
        <p>
          To redeem a voucher, follow the instructions provided on the XVoucher
          website. Upon redemption, the equivalent amount of USDT, less a 8.65%
          fee, will be credited to your account. For example, redeeming a R100
          voucher will result in an estimated R91.35 worth of USDT being
          credited to your account. The redemption process requires the entry of
          a 16-digit voucher code, and users must ensure the accuracy of the
          information provided. Note that fees may be higher depending on
          network conditions and other factors, and XVoucher is not liable for
          any variations in fees.
        </p>

        <h2>5. Usage of USDT</h2>
        <p>
          The USDT obtained through our platform can only be used at selected
          retailers that accept Scan to Pay. A list of participating retailers
          is available on the Scan to Pay website. The USDT cannot be withdrawn,
          transferred, or used outside the specified network of retailers. Users
          are advised to check the list of participating retailers periodically,
          as it may be updated.
        </p>

        <h2>6. Fees</h2>
        <p>
          A fee starting at 8.65% is applied to each voucher redemption. This
          fee is deducted from the total amount before the USDT is credited to
          your account. Please note that fees may vary and can sometimes be
          higher due to network conditions or other factors. XVoucher is not
          responsible for any additional fees incurred during the redemption
          process.
        </p>

        <h2>7. No Withdrawal and No Refund Policy</h2>
        <p>
          Funds redeemed through XVoucher are non-withdrawable and must be used
          within the closed-loop system of participating South African
          retailers. Users acknowledge and agree that once USDT is credited to
          their account, it cannot be converted back to fiat currency or
          transferred to other wallets or platforms.{" "}
          <strong>No refunds are provided once USDT has been redeemed.</strong>{" "}
          Users must ensure they understand and accept this policy before
          proceeding with any redemption.
        </p>

        <h2>8. User Responsibilities</h2>
        <p>
          Users are responsible for maintaining the confidentiality of their
          account information and are fully responsible for all activities that
          occur under their account. Users agree to immediately notify XVoucher
          of any unauthorized use of their account or any other breach of
          security. As XVoucher utilizes decentralized, non-custodial wallet
          technology, users are solely responsible for the security of their
          wallets and private keys. XVoucher does not hold or manage any user
          funds and cannot be held liable for any loss or theft of funds due to
          user negligence.
        </p>

        <h2>9. Compliance with Laws</h2>
        <p>
          Users agree to comply with all applicable laws, regulations, and rules
          regarding the use of XVoucher's services and the redemption and use of
          USDT. XVoucher is not responsible for any violations of such laws by
          users. Users must ensure that their use of the services does not
          violate any local, national, or international laws or regulations.
        </p>

        <h2>10. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, XVoucher shall not be liable
          for any indirect, incidental, special, consequential, or punitive
          damages, or any loss of profits or revenues, whether incurred directly
          or indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from (i) your use of or inability to use the
          services; (ii) any unauthorized access to or use of our servers and/or
          any personal information stored therein; (iii) any interruption or
          cessation of transmission to or from the services; (iv) any bugs,
          viruses, trojan horses, or the like that may be transmitted to or
          through our services by any third party; (v) any errors or omissions
          in any content or for any loss or damage incurred as a result of the
          use of any content posted, emailed, transmitted, or otherwise made
          available through the services; and/or (vi) the defamatory, offensive,
          or illegal conduct of any third party.
        </p>

        <h2>11. Indemnification</h2>
        <p>
          You agree to indemnify and hold XVoucher and its subsidiaries,
          affiliates, officers, agents, employees, partners, and licensors
          harmless from any claim or demand, including reasonable attorneys'
          fees, made by any third party due to or arising out of your use of the
          services, your violation of these terms, or your violation of any
          rights of another. This includes any claims arising from your failure
          to comply with any applicable laws or regulations.
        </p>

        <h2>12. Modifications to the Service</h2>
        <p>
          XVoucher reserves the right to modify or discontinue, temporarily or
          permanently, the service (or any part thereof) with or without notice.
          You agree that XVoucher shall not be liable to you or to any third
          party for any modification, suspension, or discontinuance of the
          service. Any changes to the services will be communicated to users via
          the XVoucher website or other appropriate channels.
        </p>

        <h2>13. Governing Law</h2>
        <p>
          These terms shall be governed and construed in accordance with the
          laws of South Africa, without regard to its conflict of law
          provisions. Our failure to enforce any right or provision of these
          terms will not be considered a waiver of those rights. If any
          provision of these terms is held to be invalid or unenforceable by a
          court, the remaining provisions of these terms will remain in effect.
        </p>

        <h2>14. Anti-Money Laundering (AML) Compliance</h2>
        <p>
          XVoucher is committed to complying with all applicable AML laws and
          regulations. Users agree not to use our services for any illegal or
          fraudulent activities, including money laundering, terrorism
          financing, or other criminal activities. We reserve the right to
          monitor transactions and report suspicious activities to the relevant
          authorities. Users may be required to provide additional information
          or documentation to verify their identity and the source of their
          funds.
        </p>

        <h2>15. General Data Protection Regulation (GDPR) Compliance</h2>
        <p>
          XVoucher respects your privacy and is committed to protecting your
          personal data. We comply with the GDPR and other applicable data
          protection laws. By using our services, you consent to the collection,
          use, and disclosure of your personal data as described in our Privacy
          Policy. You have the right to access, correct, delete, and restrict
          the processing of your personal data. For more information, please see
          our Privacy Policy. Users can exercise their data protection rights by
          contacting us at hello@xvoucher.io.
        </p>

        <h2>16. Cookie Policy</h2>
        <p>
          We use cookies to improve your experience on our website. By using our
          services, you consent to our use of cookies in accordance with this
          policy. Cookies are small text files stored on your device that help
          us understand how you use our website and enhance your user
          experience. You can manage your cookie preferences through your
          browser settings. However, disabling cookies may affect the
          functionality of our website. For more information on our use of
          cookies, please refer to our Cookie Policy.
        </p>

        <h2>17. Non-Custodial Wallets and User Responsibility</h2>
        <p>
          XVoucher's services involve the use of decentralized, non-custodial
          wallets. Users can connect any existing web3 wallet or create one via
          email or mobile. These wallets are non-custodial, meaning that
          XVoucher does not hold, manage, or have access to any user funds.
          Users are solely responsible for the security of their wallets and
          private keys. It is imperative that users do not share their wallet
          credentials or seed phrases with anyone, as this can lead to loss or
          theft of funds. XVoucher will not be held liable for any losses
          resulting from user negligence or failure to secure their wallet
          information.
        </p>

        <h2>18. Intellectual Property Rights</h2>
        <p>
          XVoucher owns all intellectual property and other rights in the
          platform and its contents, including but not limited to software,
          text, images, trademarks, service marks, copyrights, patents, designs,
          and its “look and feel.” Users are not permitted to use any of these
          materials without the express written consent of XVoucher. Any
          unauthorized use of our intellectual property may result in legal
          action.
        </p>

        <h2>19. Additional Rights</h2>
        <p>
          We reserve the right to (a) modify, substitute, eliminate, or add to
          the platform at any time; (b) review, modify, filter, disable, delete,
          and remove any and all content and information from the platform; and
          (c) cooperate with any law enforcement, court, or government
          investigation or order, or third party requesting or directing that we
          disclose information or content that you provide. These rights do not
          constitute obligations of XVoucher.
        </p>

        <h2>20. Prohibited Activity</h2>
        <p>
          Users agree not to engage in any of the following prohibited
          activities: (a) infringing on any intellectual property rights; (b)
          engaging in cyberattacks or other activities that compromise the
          security or proper functioning of our platform; (c) providing false or
          misleading information; (d) engaging in market manipulation; (e)
          violating any laws related to securities or derivatives trading; (f)
          buying, selling, or transferring stolen property; (g) engaging in data
          mining or scraping activities; (h) posting objectionable content; and
          (i) engaging in any other unlawful conduct. Violation of these
          prohibitions may result in the termination of your access to our
          services.
        </p>

        <h2>21. Assumption of Risk</h2>
        <p>
          By accessing and using our platform, you acknowledge and accept the
          inherent risks associated with using cryptographic and
          blockchain-based systems. These risks include, but are not limited to,
          market volatility, security vulnerabilities, and regulatory changes.
          Users should be aware that blockchain transactions are irreversible
          and that the value of digital assets can fluctuate significantly.
          XVoucher does not control the underlying technology and cannot be held
          responsible for any losses incurred by users.
        </p>

        <h2>22. Third-Party Resources and Promotions</h2>
        <p>
          Our platform may contain references or links to third-party resources,
          including information, materials, products, or services that we do not
          own or control. We do not endorse, monitor, or assume any
          responsibility for these third-party resources. Users access these
          resources at their own risk and should review the terms and conditions
          of any third-party services they use. XVoucher is not liable for any
          losses or damages resulting from the use of third-party resources.
        </p>

        <h2>23. Release of Claims</h2>
        <p>
          Users expressly agree to assume all risks in connection with their use
          of our platform. Users waive and release XVoucher from any and all
          liability, claims, causes of action, or damages arising from or in any
          way related to their use of the platform. This release includes, but
          is not limited to, claims related to unauthorized access, data
          breaches, and other security incidents.
        </p>

        <h2>24. Indemnity</h2>
        <p>
          Users agree to indemnify and hold harmless XVoucher and its officers,
          directors, employees, contractors, agents, affiliates, and
          subsidiaries from any claims, damages, obligations, losses,
          liabilities, costs, and expenses arising from their use of the
          platform, violation of these terms, or violation of any third-party
          rights. This indemnification includes any claims arising from users'
          failure to comply with applicable laws or regulations.
        </p>

        <h2>25. No Warranties</h2>
        <p>
          The platform is provided "AS IS" and "AS AVAILABLE" without any
          warranties, express or implied. XVoucher disclaims any warranties of
          merchantability, fitness for a particular purpose, or
          non-infringement. Users acknowledge that their use of the platform is
          at their own risk and that XVoucher does not guarantee continuous,
          uninterrupted, or secure access to the platform.
        </p>

        <h2>26. Limitation of Liability</h2>
        <p>
          Under no circumstances shall XVoucher or any of its officers,
          directors, employees, contractors, agents, affiliates, or subsidiaries
          be liable for any indirect, punitive, incidental, special,
          consequential, or exemplary damages, including, but not limited to,
          damages for loss of profits, goodwill, use, data, or other intangible
          property, arising out of or relating to any access or use of the
          platform. XVoucher assumes no liability or responsibility for any
          errors, mistakes, or inaccuracies of content; personal injury or
          property damage resulting from access or use of the platform;
          unauthorized access to or use of secure servers or databases;
          interruption or cessation of function related to the platform; bugs,
          viruses, trojan horses, or the like that may be transmitted to or
          through the platform; errors or omissions in content; and the
          defamatory, offensive, or illegal conduct of any third party.
        </p>

        <h2>27. Dispute Resolution</h2>
        <p>
          We will use our best efforts to resolve any potential disputes through
          informal, good faith negotiations. If a potential dispute arises,
          users must contact us by sending an email to hello@xvoucher.io so that
          we can attempt to resolve it without resorting to formal dispute
          resolution. If we are not able to reach an informal resolution within
          sixty days of the email, users agree to resolve the potential dispute
          through binding arbitration. The arbitration shall be held on a
          confidential basis before a single arbitrator in South Africa unless
          both parties agree to hold it elsewhere. Any judgment on the award
          rendered by the arbitrator may be entered in any court of competent
          jurisdiction.
        </p>

        <h2>28. Class Action and Jury Trial Waiver</h2>
        <p>
          Users must bring any and all disputes against XVoucher in their
          individual capacity and not as a plaintiff in or member of any
          purported class action, collective action, private attorney general
          action, or other representative proceeding. This provision applies to
          class arbitration. Users and XVoucher both agree to waive the right to
          demand a trial by jury.
        </p>

        <h2>29. Governing Law</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of South Africa, without regard to principles of conflict of
          laws. Any arbitration conducted pursuant to this Agreement shall be
          governed by the Arbitration Rules of the Centre. Users agree that the
          courts of South Africa are the proper forum for any appeals of an
          arbitration award or for court proceedings in the event that this
          Agreement's binding arbitration clause is found to be unenforceable.
        </p>

        <h2>30. Entire Agreement</h2>
        <p>
          These terms constitute the entire agreement between users and XVoucher
          with respect to the subject matter hereof. This Agreement supersedes
          any and all prior or contemporaneous written and oral agreements,
          communications, and other understandings (if any) relating to the
          subject matter of these terms.
        </p>

        <h2>31. Gas Fees</h2>
        <p>
          Blockchain transactions require the payment of transaction fees to the
          appropriate network (“Gas Fees”). Users will be solely responsible for
          paying the Gas Fees for any transaction that they initiate. XVoucher
          is not responsible for any variations in Gas Fees and cannot be held
          liable for any additional costs incurred by users.
        </p>
      </div>
      <div className="footer">
        {/* <p>
          Looking to get USDT?{" "}
          <a href="https://www.prepaid24.co.za/pages/blu-vouchers">
            Buy BluVouchers online
          </a>
          .
        </p> */}
      </div>
    </div>
  );
};

export default Terms;
