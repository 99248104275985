import React from "react";
import EmailCollector from "../../Mailchimp/EmailCollector";
import "./styles.css";

const FooterMobile = ({ screenType }) => {
  return (
    <footer className="footer-mobile">
      <div className="overlap-wrapper-new">
        <div className="overlap-new">
          <div className="frame-5-new">
            <div className="frame-6-new">
              <a href="/" className="xvoucher-logo-link">
                <img
                  className="img-new"
                  alt="Xvoucher logo"
                  src="/img/xvoucher-logo-m1.svg"
                />
              </a>
              <div className="frame-7-new">
                <div className="frame-8-new">
                  <div className="text-wrapper-8-new">
                    <a href="/how-to-guide" className="link-style">
                      How to Redeem
                    </a>
                  </div>

                  <div className="text-wrapper-9-new">
                    <a href="https://xvoucher.io/terms" className="link-style">
                      Terms And Conditions
                    </a>
                  </div>
                </div>
                <div className="frame-9-new">
                  <div className="text-wrapper-8-new">
                    <a href="/privacy-policy" className="link-style">
                      Privacy Policy
                    </a>
                  </div>
                  <div className="text-wrapper-9-new">
                    <a href="mailto:hello@xvoucher.io" className="link-style">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <EmailCollector screenType={screenType} page="main" />
            <a
              className="element-voucher"
              href="https://www.prepaid24.co.za/pages/blu-vouchers"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="overlap-4">
                <div className="rectangle-3" />
                <div className="get-your-here">Buy a</div>
                <img
                  className="clip-path-group"
                  alt="Clip path group"
                  src="/img/vector-6.png"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="social-icons">
        {/* First Social Icon */}
        <a
          href="https://x.com/x_voucher"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="social-icon" alt="Twitter" src="/img/social-x.svg" />
        </a>

        {/* Second Social Icon */}
        <a
          href="https://t.me/xvoucherchat"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="social-icon"
            alt="Telegram"
            src="/img/social-tel.svg"
          />
        </a>

        {/* Third Social Icon */}
        <a
          href="https://medium.com/@xvoucher"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="social-icon" alt="Medium" src="/img/social-Med.svg" />
        </a>
      </div>

      <div className="frame-12-new">
        <p className="fantasy-go-pty-ltd-new">
          XVoucher trading as XVoucher
          <br />
          Fourways, 39 Lombardy Avenue
          <br />
          Johannesburg, Gauteng
          <br />
          South Africa
          <br />
          2021
        </p>

        <div className="frame-13-new">
          <p className="DISCLAIMER-new">
            Xvoucher (Pty) Ltd enables the seamless redemption of vouchers into
            stablecoins, allowing users to spend their digital assets globally.
            Xvoucher complies with all applicable financial regulations and
            operates under the necessary licenses for secure and efficient
            transactions.
            <br />
            For any assistance or inquiries, please contact us at
            support@xvoucher.com or reach us via our customer service hotline.
          </p>
          <div className="frame-14-new">
            <div className="frame-15-new">
              <div className="link-new">
                <div className="national-responsible-new">
                  24/7 CUSTOMER SUPPORT
                </div>
              </div>
              {/* <div className="national-gambling-wrapper-new">
                      <div className="national-gambling-new">
                        FINANCIAL COMPLIANCE
                      </div>
                    </div> */}
              <div className="financial-wrapper-new">
                <div className="financial-new">LOWEST FEES</div>
              </div>
              <div className="fantasygo-self-wrapper-new">
                <div className="fantasygo-self-new">SPEND ANYTIME</div>
              </div>
            </div>
            <img
              className="group-2-new"
              alt="Group"
              src="/img/group-39557.png"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterMobile;
