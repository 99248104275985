import React from "react";
import "./styles.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="nav">
        <a href="/" className="xvoucher-logo-link">
          <img
            className="xvoucher-logo"
            alt="Xvoucher logo"
            src="/img/xvoucher-logo.svg"
          />
        </a>
        <div className="how-guide">
          <div className="overlap-group">
            <a href="/how-to-guide" className="text-wrapper">
              how to guide
            </a>
            <img className="vector" alt="Vector" src="/img/vector.svg" />
          </div>
        </div>
      </div>
      <div className="content">
        <h1>Privacy Policy</h1>
        <p>Last modified: 27 June 2024</p>

        <h2>Introduction</h2>
        <p>
          XVoucher is committed to protecting your privacy. This Privacy Policy
          describes how we collect, use, and safeguard your personal information
          when you use our platform, services, and website. By accessing or
          using our services, you consent to the practices described in this
          policy.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect both personal and non-personal information from you.
          Personal information includes details that can identify you directly,
          such as your name, email address, and phone number. Non-personal
          information may include data that cannot be used to directly identify
          you, such as anonymized data or aggregated information about how users
          interact with our platform.
        </p>

        <h2>2. Use of Information</h2>
        <p>
          We use your information to provide, improve, and personalize our
          services. This includes processing transactions, managing your
          account, and communicating with you about our services. We may also
          use your information to detect, prevent, and address fraud or other
          security issues.
        </p>

        <h2>3. Sharing of Information</h2>
        <p>
          We do not share your personal information with third parties, except
          as necessary to provide our services or comply with legal
          requirements. We may share anonymized or aggregated data that does not
          identify you personally.
        </p>

        <h2>4. Data Security</h2>
        <p>
          We implement security measures to protect your personal information.
          However, no electronic transmission or storage is 100% secure, and we
          cannot guarantee absolute security. You are responsible for
          safeguarding your account information and personal access details.
        </p>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our website may use cookies and other tracking technologies to enhance
          your experience and improve our services. Cookies are small text files
          that are stored on your device to track and personalize your
          experience on our platform.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal data.
          To make changes or to request the deletion of your information, please
          contact us at hello@xvoucher.io.
        </p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes to
          our practices or for other operational, legal, or regulatory reasons.
          We encourage you to review this page periodically for any changes.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or our data
          practices, please contact us at hello@xvoucher.io.
        </p>
      </div>
      <div className="footer">
        {/* <p>
          Looking to get USDT?{" "}
          <a href="https://www.prepaid24.co.za/pages/blu-vouchers">
            Buy BluVouchers online
          </a>
          .
        </p> */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
