import React from "react";
import "./styles.css";
import FooterDesktop from "../../../components/Footer/desktop/FooterDesktop";

const HowToGuideD = ({ screenType }) => {
  return (
    <div className="how-to-guide-d">
      <div className="content-wrapper">
        <div className="nav">
          <a href="/" className="xvoucher-logo-link">
            <img
              className="xvoucher-logo"
              alt="Xvoucher logo"
              src="/img/xvoucher-logo.svg"
            />
          </a>
          <div className="how-guide">
            <div className="overlap-group">
              <a href="/how-to-guide" className="text-wrapper">
                how to guide
              </a>
              <img className="vector" alt="Vector" src="/img/vector.svg" />
            </div>
          </div>
        </div>
        <div className="content">
          <h1>How to Use X Voucher</h1>
          {/* <div className="text-wraps">
            Follow these steps to redeem your voucher for USDT stablecoin
          </div> */}
          <ul className="steps-list">
            <li>
              <strong>
                Make sure you are visiting https://www.xvoucher.io/
                <br></br>
                <br></br>
              </strong>{" "}
              On the homepage, click on the "Connect Wallet" button in the
              middle of the page to either create a new wallet or log in to an
              existing wallet with phone number.
            </li>
            <li>
              <strong>Enter Your Phone Number:</strong> If you don't have a
              wallet, follow the prompts to create one. If you already have a
              wallet, log in using your phone number.
            </li>
            <li>
              <strong>Redeem Your Voucher:</strong> Once logged in, enter the
              16-digit voucher number and click "Redeem".
            </li>
            <li>
              <strong>View Your USDT Balance:</strong> After redeeming, the
              system will display the equivalent amount in USDT (Tether).
            </li>
            <li>
              <strong>Download Scan to Pay App:</strong> Download the Scan to
              Pay app from the{" "}
              <a href="https://play.google.com/store/apps/details?id=com.oltio.capitec.masterpass">
                Google Play Store{" "}
              </a>
              or the{" "}
              <a href="https://apps.apple.com/za/app/masterpass-scan-to-pay/id1084707614">
                Apple App Store
              </a>
              .
            </li>
            <li>
              <strong>Shop Nationwide:</strong> Use the Scan to Pay app to shop
              online or in-store at participating retailers. For a list of where
              you can shop, visit the{" "}
              <a href="https://www.scantopay.io/our-merchants">
                Scan to Pay retailers page
              </a>
              .
            </li>
            <li>
              <strong>Buy More Vouchers:</strong> If you need more USDT, you can
              buy blu vouchers online. Currently, we are supporting bluvoucher
              with plans to expand to other voucher partners globally.
            </li>
            <li>
              <strong>Fees:</strong> A fee of approximately 8.65% is applied
              when redeeming a voucher for crypto. For example, redeeming a R100
              voucher will give you an estimated R91.35 in USDT.
            </li>
          </ul>
        </div>
        <FooterDesktop screenType={screenType} />
      </div>
    </div>
  );
};

export default HowToGuideD;
