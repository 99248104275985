import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Desktop from "./src/screens/desktop";
import Ipad from "./src/screens/ipad";
import Mobile from "./src/screens/mobile";
import HowToGuideD from "./src/screens/HowToGuide/HowToGuide-D";
import HowToGuideI from "./src/screens/HowToGuide/HowToGuide-I";
import HowToGuideM from "./src/screens/HowToGuide/HowToGuide-M";
import Terms from "./src/screens/Terms/index";
import PrivacyPolicy from "./src/screens/Privacy/index";
import "./styleguide.css";

const LoadingScreen = ({ onComplete }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [message, setMessage] = useState("");
  const [showImage, setShowImage] = useState(true); // To handle the 2-second image display

  const messages = ["REDEEM ", "VOUCHERS", "FOR", "STABLECOINS"];

  useEffect(() => {
    // Show the image for 2 seconds before transitioning to messages
    const imageTimeout = setTimeout(() => {
      setShowImage(false); // Hide the image after 2 seconds
    }, 2000);

    return () => clearTimeout(imageTimeout); // Clean up timeout on unmount
  }, []);

  useEffect(() => {
    if (!showImage) {
      // Start displaying messages once the image disappears
      let index = 0;
      const messageInterval = setInterval(() => {
        if (index < messages.length) {
          setMessage(messages[index]);
          index++;
        } else {
          clearInterval(messageInterval);
          setTimeout(() => onComplete(), 1000); // After all messages, redirect to website
        }
      }, 1000); // Each message shows for 2 seconds
    }
  }, [showImage, onComplete]);

  return (
    <div style={styles.loadingContainer}>
      {showImage ? (
        <div style={styles.coinContainer}>
          <img
            src="https://media.giphy.com/media/XH8aAiiVNuTaPVBLKd/giphy.gif"
            alt="Loading Icon"
            style={styles.loadingIcon}
          />
        </div>
      ) : (
        <h2 style={styles.messageText}>{message}</h2>
      )}
    </div>
  );
};

const styles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#06d758",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    textAlign: "center",
  },
  coinContainer: {
    position: "relative",
  },
  loadingIcon: {
    width: "100px",
    height: "100px",
  },
  messageText: {
    color: "#fff", // Set message text to white
    fontSize: "32px", // Set font size to 24px
    fontFamily: "'Cherry Bomb-Regular', Helvetica, sans-serif", // Correct font-family syntax
  },
};

const App = () => {
  const [screenType, setScreenType] = useState(
    window.innerWidth < 768
      ? "mobile"
      : window.innerWidth < 1024
      ? "ipad"
      : "desktop"
  );

  const [loading, setLoading] = useState(true);

  const handleLoadingComplete = () => {
    setLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenType("mobile");
      } else if (window.innerWidth < 1024) {
        setScreenType("ipad");
      } else {
        setScreenType("desktop");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return <LoadingScreen onComplete={handleLoadingComplete} />;
  }

  return (
    <Router>
      <Helmet>
        <title>XVoucher - Redeem Vouchers for Stablecoin</title>
        <meta
          name="description"
          content="Redeem your vouchers for stablecoin easily and securely with XVoucher. Convert vouchers to stablecoin, buy crypto with vouchers, and manage your digital assets seamlessly. Join the future of digital payments with XVoucher."
        />
        <meta
          name="keywords"
          content="voucher to crypto, voucher to stablecoin, buy crypto with vouchers, cash to crypto, redeem vouchers, digital assets, crypto payments, secure crypto conversion"
        />
        <meta name="author" content="X Voucher Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="UTF-8" />
      </Helmet>
      <Routes>
        <Route
          path="/"
          element={
            screenType === "mobile" ? (
              <Mobile screenType={screenType} />
            ) : screenType === "ipad" ? (
              <Ipad screenType={screenType} />
            ) : (
              <Desktop screenType={screenType} />
            )
          }
        />
        <Route
          path="/how-to-guide"
          element={
            <>
              <Helmet>
                <title>XVoucher - How To Guide</title>
                <meta
                  name="description"
                  content="Learn how to redeem your vouchers for stablecoin with XVoucher. Step-by-step guide to converting vouchers to stablecoin, buying crypto with vouchers, and using your digital assets for secure transactions."
                />
                <meta
                  name="keywords"
                  content="how to redeem vouchers, voucher to crypto guide, redeem vouchers for crypto, convert vouchers to stablecoin, buy crypto with vouchers, digital assets guide, secure crypto transactions"
                />
              </Helmet>
              {screenType === "mobile" ? (
                <HowToGuideM />
              ) : screenType === "ipad" ? (
                <HowToGuideI />
              ) : (
                <HowToGuideD />
              )}
            </>
          }
        />
        <Route
          path="/terms"
          element={
            <>
              <Helmet>
                <title>XVoucher - Terms and Conditions</title>
                <meta
                  name="description"
                  content="Read the terms and conditions for using XVoucher services. Understand the policies and guidelines for redeeming vouchers for stablecoin, converting vouchers to stablecoin, and managing your digital assets securely."
                />
                <meta
                  name="keywords"
                  content="XVoucher terms, voucher to crypto terms, redeem vouchers for crypto, convert vouchers to stablecoin, digital assets terms, crypto conversion policies, XVoucher guidelines"
                />
              </Helmet>
              <Terms />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <Helmet>
                <title>XVoucher - Privacy Policy</title>
                <meta
                  name="description"
                  content="Read the privacy policy for using XVoucher services. Understand how we collect, use, and protect your data while you redeem vouchers and manage your digital assets securely."
                />
                <meta
                  name="keywords"
                  content="XVoucher privacy policy, data protection, user privacy, voucher to crypto privacy, digital assets privacy, crypto conversion data"
                />
              </Helmet>
              <PrivacyPolicy />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
