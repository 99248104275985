import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import EmailCollector from "../../components/Mailchimp/EmailCollector";
import FooterTablet from "../../components/Footer/tablet/FooterTablet";

const Ipad = ({ screenType }) => {
  const isLocalhost = window.location.hostname === "localhost";

  return (
    <div className="tablet">
      <div className="overlap">
        <div className="nav">
          <a href="/" className="xvoucher-logo-link">
            <img
              className="xvoucher-logo"
              alt="Xvoucher logo"
              src="/img/xvoucher-logo.svg"
            />
          </a>
          <div className="how-guide">
            <div className="overlap-group">
              <a href="/how-to-guide" className="text-wrapper">
                how to guide
              </a>
              <img className="vector" alt="Vector" src="/img/vector.svg" />
            </div>
          </div>
        </div>

        <div className="header-copy">Redeem</div>
        <div className="header-subcopy">Vouchers for Stabelcoins</div>

        <iframe
          className="step-iframe bg-transparent"
          src={
            isLocalhost
              ? "http://localhost:3000"
              : "https://voucher-poc-dij5-aerynquarmbys-projects.vercel.app/"
          }
          title="Embedded Step"
        />
        <div className="whats-it-about">
          <img className="a6" alt="a6" src="/img/A6.svg" />

          {/* First Point */}
          <p className="p">Redeem & Spend In 3 Easy Steps</p>

          {/* Follow-up Points */}
          <div className="flexcontainer">
            {/* First Follow-up */}
            <p className="textabout">
              Connect wallet, choose ZA, enter mobile number.
            </p>

            {/* Second Follow-up */}
            <p className="textabout">Enter 16-digit voucher code.</p>

            {/* Third Follow-up */}
            <p className="textabout">
              Add crypto wallet to Scan To Pay, start spending nationwide.
            </p>
          </div>
        </div>
        <div className="spend-it-copy">
          Download Scan to Pay to start spending nationwide, available on Google
          Play & Apple store.
        </div>
        <div className="spend-it">
          {/* First Image */}
          <a
            href="https://www.scantopay.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="scan-to-pay-logos"
              alt="Scan to Pay"
              src="/img/scantopay-social.svg"
            />
          </a>

          {/* Second Image */}
          <a
            href="https://apps.apple.com/za/app/masterpass-scan-to-pay/id1084707614"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="apple" alt="Apple App Store" src="/img/apple.svg" />
          </a>

          {/* Third Image */}
          <a
            href="https://play.google.com/store/apps/details?id=com.oltio.capitec.masterpass"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="google"
              alt="Google Play Store"
              src="/img/google.svg"
            />
          </a>

          <img
            className="brand-logos"
            alt="Brand logos"
            src="/img/brand-logos.png"
          />
          <p className="textabout-wrapper-7">Spend @ 800k+ Stores</p>
        </div>
        <FooterTablet screenType={screenType} />
      </div>
    </div>
  );
};

export default Ipad;
