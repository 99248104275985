import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import FooterDesktop from "../../components/Footer/desktop/FooterDesktop"; // Import FooterDesktop component

const Desktop = ({ screenType }) => {
  const isLocalhost = window.location.hostname === "localhost";

  // Create refs for each point
  const pRef1 = useRef(null);
  const pRef2 = useRef(null);
  const pRef3 = useRef(null);
  const pRef4 = useRef(null);

  // States to track which points have been animated
  const [showPoint1, setShowPoint1] = useState(false);
  const [showPoint2, setShowPoint2] = useState(false);
  const [showPoint3, setShowPoint3] = useState(false);
  const [showPoint4, setShowPoint4] = useState(false);

  // Scroll handler to animate points based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const points = [
        { ref: pRef1, show: showPoint1, setShow: setShowPoint1 },
        { ref: pRef2, show: showPoint2, setShow: setShowPoint2 },
        { ref: pRef3, show: showPoint3, setShow: setShowPoint3 },
        { ref: pRef4, show: showPoint4, setShow: setShowPoint4 },
      ];

      points.forEach((point) => {
        if (!point.show) {
          const pointElement = point.ref.current;
          const rect = pointElement.getBoundingClientRect();
          const scrollPosition = window.scrollY + window.innerHeight;

          // If element is in the viewport, show it
          if (scrollPosition > rect.top + window.scrollY) {
            point.setShow(true);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showPoint1, showPoint2, showPoint3, showPoint4]);

  return (
    <div className="desktop">
      <div className="overlap">
        <div className="nav">
          <a href="/" className="xvoucher-logo-link">
            <img
              className="xvoucher-logo"
              alt="Xvoucher logo"
              src="/img/xvoucher-logo.svg"
            />
          </a>
          <div className="how-guide">
            <div className="overlap-group">
              <a href="/how-to-guide" className="text-wrapper">
                how to guide
              </a>
              <img className="vector" alt="Vector" src="/img/vector.svg" />
            </div>
          </div>
        </div>

        <div className="header-copy">Redeem</div>
        <div className="header-subcopy">Vouchers for Stablecoins</div>

        <iframe
          className="step-iframe bg-transparent"
          src={
            isLocalhost
              ? "http://localhost:3000"
              : "https://voucher-poc-dij5-aerynquarmbys-projects.vercel.app/"
          }
          title="Embedded Step"
        />

        <div className="whats-it-about">
          <img className="a6" alt="a6" src="/img/A6.svg" />

          {/* First Point */}
          <p className="p" ref={pRef1}>
            Redeem & Spend In 3 Easy Steps
          </p>

          {/* Follow-up Points */}
          <div className="flexcontainer">
            {/* First Follow-up */}
            <p
              className={`textabout ${showPoint2 ? "animate" : ""}`}
              ref={pRef2}
            >
              Connect wallet, choose ZA, enter mobile number.
            </p>

            {/* Second Follow-up */}
            <p
              className={`textabout ${showPoint3 ? "animate" : ""}`}
              ref={pRef3}
            >
              Enter 16-digit voucher code.
            </p>

            {/* Third Follow-up */}
            <p
              className={`textabout ${showPoint4 ? "animate" : ""}`}
              ref={pRef4}
            >
              Add crypto wallet to Scan To Pay, start spending nationwide.
            </p>
          </div>
        </div>
        <div className="spend-it-copy">
          Download Scan to Pay to start spending nationwide, available on Google
          Play & Apple store.
        </div>

        <div className="spend-it">
          {/* First Image */}
          <a
            href="https://www.scantopay.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="scan-to-pay-logos"
              alt="Scan to Pay"
              src="/img/scantopay-social.svg"
            />
          </a>

          {/* Second Image */}
          <a
            href="https://apps.apple.com/za/app/masterpass-scan-to-pay/id1084707614"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="apple" alt="Apple App Store" src="/img/apple.svg" />
          </a>

          {/* Third Image */}
          <a
            href="https://play.google.com/store/apps/details?id=com.oltio.capitec.masterpass"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="google"
              alt="Google Play Store"
              src="/img/google.svg"
            />
          </a>

          <img
            className="brand-logos"
            alt="Brand logos"
            src="/img/brand-logos.png"
          />
          <p className="textabout-wrapper-7">Spend @ 800k+ Stores</p>
        </div>

        {/* Add the FooterDesktop component here */}
        <FooterDesktop screenType={screenType} />
      </div>
    </div>
  );
};

export default Desktop;
