import React, { useState, useRef, useEffect } from "react";

const EmailCollector = () => {
  const [isRevealed, setIsRevealed] = useState(false);
  const [scratchProgress, setScratchProgress] = useState(0);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Draw the initial scratch layer
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the "Scratch Me!" text on the canvas
      ctx.fillStyle = "#000000";
      ctx.font = "16px Gineso-NormRegular, Helvetica";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("Scratch Me!", canvas.width / 2, canvas.height / 2);

      // Add mouse events
      canvas.addEventListener("mousedown", startScratching);
      canvas.addEventListener("mousemove", handleScratch);
      canvas.addEventListener("mouseup", stopScratching);
      canvas.addEventListener("mouseleave", stopScratching);

      // Add touch events
      canvas.addEventListener("touchstart", startScratching);
      canvas.addEventListener("touchmove", handleTouchScratch);
      canvas.addEventListener("touchend", stopScratching);

      return () => {
        // Remove mouse events
        canvas.removeEventListener("mousedown", startScratching);
        canvas.removeEventListener("mousemove", handleScratch);
        canvas.removeEventListener("mouseup", stopScratching);
        canvas.removeEventListener("mouseleave", stopScratching);

        // Remove touch events
        canvas.removeEventListener("touchstart", startScratching);
        canvas.removeEventListener("touchmove", handleTouchScratch);
        canvas.removeEventListener("touchend", stopScratching);
      };
    }
  }, []);

  const startScratching = (e) => {
    e.preventDefault();
    canvasRef.current.isDrawing = true;
  };

  const stopScratching = (e) => {
    e.preventDefault();
    canvasRef.current.isDrawing = false;
    checkScratchProgress();
  };

  const handleScratch = (e) => {
    if (canvasRef.current.isDrawing) {
      scratch(e.clientX, e.clientY);
    }
  };

  const handleTouchScratch = (e) => {
    if (canvasRef.current.isDrawing) {
      const touch = e.touches[0];
      scratch(touch.clientX, touch.clientY);
    }
  };

  const scratch = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const posX = x - rect.left;
    const posY = y - rect.top;
    ctx.globalCompositeOperation = "destination-out";
    ctx.beginPath();
    ctx.arc(posX, posY, 10, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
    calculateScratchProgress(ctx);
  };

  const calculateScratchProgress = (ctx) => {
    const imageData = ctx.getImageData(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    const pixels = imageData.data;
    let scratchedPixels = 0;
    const totalPixels = pixels.length / 4; // 4 values per pixel (RGBA)

    for (let i = 3; i < pixels.length; i += 4) {
      if (pixels[i] === 0) scratchedPixels++;
    }

    const progress = (scratchedPixels / totalPixels) * 100;
    setScratchProgress(progress);

    if (progress > 50) {
      setIsRevealed(true);
    }
  };

  const checkScratchProgress = () => {
    if (scratchProgress > 50) {
      setIsRevealed(true);
    }
  };

  return (
    <div className="email-collector">
      {!isRevealed ? (
        <div className="scratch-card">
          <canvas ref={canvasRef} width={180} height={36} />
        </div>
      ) : (
        <a
          href="https://t.me/xvoucherchat"
          target="_blank"
          rel="noopener noreferrer"
          className="reveal-container"
        >
          <p>🎉 Join The Community! 🎉</p>
          <p>@xvoucherchat on Telegram</p>
        </a>
      )}
    </div>
  );
};

export default EmailCollector;
